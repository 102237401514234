define('auth-app/mixins/generate-input-id', ['exports', 'formation-ember-components/mixins/generate-input-id', 'npm:node-uuid'], function (exports, _generateInputId, _npmNodeUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _generateInputId.default;
    }
  });
});
define('auth-app/initializers/service', ['exports', 'auth-app/services/login'], function (exports, _login) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    Ember.debug('Service initializer');

    application.register('service:i18n', _login.default, {
      singleton: true
    });

    application.register('service:login', _login.default, {
      singleton: true
    });

    application.registerOptionsForType('locale', {
      singleton: true,
      instantiate: false
    });

    application.inject('route', 'i18n', 'service:i18n');
    application.inject('model', 'i18n', 'service:i18n');

    application.inject('route', 'loginService', 'service:login');
    application.inject('controller', 'loginService', 'service:login');
  }

  exports.default = {
    name: 'service',
    initialize: initialize
  };
});
define('auth-app/models/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LoginModel = Ember.Object.extend(Ember.Validations, {

    userName: Ember.$.cookie('userName') || '',
    password: null,
    rememberMe: true,
    redirect: null,

    validations: {
      userName: {
        presence: true
      },
      password: {
        presence: true
      }
    }

  });

  exports.default = LoginModel;
});
define('auth-app/models/setup-otp', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SetupOtpModel = Ember.Object.extend(Ember.Validations, {
    key: null,
    otpUrl: null,
    user: null,
    code: null,
    validations: {
      code: {
        presence: true
      }
    }
  });

  exports.default = SetupOtpModel;
});
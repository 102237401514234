define('auth-app/controllers/forgotpassword', ['exports', 'ic-ajax'], function (exports, _icAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    disableButton: false,

    actions: {

      reset: function reset() {
        this.set('model.email', '');
      },

      submit: function submit() {
        var model = this.get('model');
        model.get('validationErrors').clear();

        if (model.validate()) {
          this.set('disableButton', true);
          var data = model.getProperties('email');
          if (model.get('validEmail')) {
            (0, _icAjax.default)({
              url: '/auth/forgotpassword',
              type: 'POST',
              data: data
            }).then(function () {
              this.set('disableButton', false);
              this.send('reset');
              this.set('resetSuccess', true);
            }.bind(this), function (response) {
              this.set('disableButton', false);
              this.send('reset');
              this.get('model.validationErrors.fullMessages').pushObject(response.jqXHR.responseText);
            }.bind(this));
          }
        }
      }
    }
  });
});
define('auth-app/routes/reset-otp', ['exports', 'auth-app/models/setup-otp', 'auth-app/mixins/unauthenticated-route'], function (exports, _setupOtp, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Route.extend(_unauthenticatedRoute.default, {
    loginService: inject.service('login'),

    controllerName: 'setup-otp',

    templateName: 'setup-otp',

    model: function model(params) {
      var token = params.token;
      return this.get('loginService').resetOtp(token).then(function (data) {
        return _setupOtp.default.create(data);
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        if (this.controllerFor('reset-otp').get('redirect')) {
          transition.abort();
        }
      },

      error: function error(_error) {
        var token = this.paramsFor('reset-otp').token;
        if (_error.jqXHR.status === 401 && token) {
          this.transitionTo('login', {
            queryParams: {
              token: token
            }
          });
        }
      }
    }

  });
});
define('auth-app/authenticators/copilot', ['exports', 'ember-simple-auth/authenticators/base', 'auth-app/config/environment', 'ic-ajax'], function (exports, _base, _environment, _icAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getInstance = function getInstance() {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      (0, _icAjax.default)({
        url: _environment.default.instanceUrl,
        type: 'GET',
        dataType: 'json'
      }).then(function (response) {
        resolve(response);
      }, function (err) {
        this.get('loggerService').error('Authentication error', err);
        reject();
      }.bind(this));
    }.bind(this));
  };

  var Authenticator = _base.default.extend({

    restore: getInstance,

    authenticate: getInstance,

    invalidate: function invalidate(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _icAjax.default)({
          url: _environment.default.logoutUrl,
          type: 'POST',
          data: data
        }).then(function () {
          resolve();
        }, function (err) {
          this.get('loggerService').error('Authentication error', err);
          reject();
        }.bind(this));
      }.bind(this));
    }

  });

  exports.default = Authenticator;
});
define('auth-app/components/qr-code', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var QRCodeComponent = Ember.Component.extend({

    didInsertElement: function didInsertElement() {
      var data = this.get('data');

      // See http://larsjung.de/qrcode/ for options
      this.$().qrcode({
        text: data
      }).addClass('qr-code');
    }

  });

  exports.default = QRCodeComponent;
});
define('auth-app/mixins/unauthenticated-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Mixin.create({
    session: inject.service('session'),
    isAuthenticated: computed.reads('session.isAuthenticated'),
    hasAccessToken: computed.notEmpty('session.data.authenticated.accesstoken'),
    beforeModel: function beforeModel() {
      // Safely leave the auth app if user is already authenticated
      if (this.get('hasAccessToken') && this.get('isAuthenticated')) {
        window.location = '/exit';
      }
    }
  });
});
define('auth-app/routes/application', ['exports', 'npm:lodash', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _npmLodash, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var getOwner = Ember.getOwner,
      inject = Ember.inject;
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    i18n: inject.service(),

    beforeModel: function beforeModel() {
      this.set('i18n.locale', 'en');
      this._injectValidationMessages();
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      this._removePreloader();
    },


    _injectValidationMessages: function _injectValidationMessages() {
      var locale = this.get('i18n.locale');
      var owner = getOwner(this);
      var translations = owner.lookup('locale:' + locale + '/translations');
      var validationMessages = translations.validationMessage;

      _npmLodash.default.forEach(validationMessages, function (message, key) {
        Ember.ValidationError.addMessages(_defineProperty({}, key, message));
      });
    },

    _removePreloader: function _removePreloader() {
      Ember.$('[data-preloader]').remove();
    },


    sessionAuthenticationFailed: function sessionAuthenticationFailed() {
      this.transitionTo('login');
    },

    sessionInvalidationSucceeded: function sessionInvalidationSucceeded() {
      this.transitionTo('login');
    },

    actions: {
      error: function error(_error) {
        if (_error.jqXHR.status === 401) {
          this.transitionTo('login');
        }
      }
    }
  });
});
define('auth-app/components/overlay-marks', ['exports', 'ember-overlays/components/overlay-marks'], function (exports, _overlayMarks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _overlayMarks.default;
    }
  });
});
define('auth-app/initializers/logger-service', ['exports', 'copilot-logger-service/services/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];
    application.register('service:logger', _logger.default, { singleton: true });
    ['route', 'controller', 'helper', 'authenticator'].forEach(function (factory) {
      application.inject(factory, 'loggerService', 'service:logger');
    });
  }

  exports.default = {
    name: 'logger-service',
    initialize: initialize
  };
});
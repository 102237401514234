define('auth-app/models/forgotpassword', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ResetPasswordModel = Ember.Object.extend(Ember.Validations, {

    email: null,

    validEmail: null,

    validations: {
      email: {
        presence: true,
        emailRegex: true
      }
    }
  });

  Ember.Validators.EmailRegexValidator = Ember.Validator.extend({
    _validate: function _validate(obj, attr, value) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // jscs:ignore maximumLineLength
      if (re.test(value)) {
        obj.set('validEmail', true);
      } else {
        obj.set('validEmail', false);
        obj.get('validationErrors').add(attr, 'invalidEmail');
      }
    }
  });

  Ember.ValidationError.addMessages({
    invalidEmail: 'Please use proper email syntax, e.g. user@example.com.'
  });

  exports.default = ResetPasswordModel;
});
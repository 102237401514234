define('auth-app/routes/login-otp', ['exports', 'auth-app/models/login-otp', 'auth-app/mixins/unauthenticated-route'], function (exports, _loginOtp, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LoginOtpRoute = Ember.Route.extend(_unauthenticatedRoute.default, {

    model: function model() {
      return _loginOtp.default.create();
    },

    actions: {
      willTransition: function willTransition(transition) {
        // authenticateSession (which gets called on submit once otp login completed)
        // will transition to root once authenticated. abort this transition here when
        // we have a window.location redirect in progress from the controller - we
        // just want to wait until window.location redirect kicks in in this case
        if (this.controllerFor('login-otp').get('redirect')) {
          transition.abort();
        }
      }
    }

  });

  exports.default = LoginOtpRoute;
});
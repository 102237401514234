define('auth-app/routes/setup-otp', ['exports', 'ic-ajax', 'auth-app/models/setup-otp', 'auth-app/mixins/unauthenticated-route'], function (exports, _icAjax, _setupOtp, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SetupOtpRoute = Ember.Route.extend(_unauthenticatedRoute.default, {

    model: function model(params) {
      var id = params.id;
      return (0, _icAjax.default)({
        url: '/auth/setup-otp/' + id,
        type: 'POST'
      }).then(function (data) {
        return _setupOtp.default.create(data);
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        // authenticateSession (which gets called on submit once otp login completed)
        // will transition to root once authenticated. abort this transition here when
        // we have a window.location redirect in progress from the controller - we
        // just want to wait until window.location redirect kicks in in this case
        if (this.controllerFor('setup-otp').get('redirect')) {
          transition.abort();
        }
      }
    }

  });

  exports.default = SetupOtpRoute;
});
define('auth-app/components/help-otp/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;


  var HelpOtp = Ember.Component.extend({
    loginService: inject.service('login'),
    flashMessages: inject.service(),
    classNames: ['help-otp'],
    classNameBindings: ['isShowing:show'],

    actions: {
      toggleHelp: function toggleHelp() {
        this.toggleProperty('isShowing');
      },
      reset2FA: function reset2FA() {
        var _this = this;

        this.get('loginService').createResetOtpToken().then(function () {
          _this.get('flashMessages').success('An email with instructions has been sent.');
        }).catch(function () {
          _this.get('flashMessages').danger('There as an error while attempting to reset your token.');
        });
      }
    }
  });

  exports.default = HelpOtp;
});
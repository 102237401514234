define('auth-app/helpers/copilot-icon', ['exports', 'auth-app/helpers/svg-jar'], function (exports, _svgJar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.copilotIcon = copilotIcon;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // This helper is implemented here in the app namespace due
  // to the fact that `svgJar` helper method is only implemented
  // in the app namespace
  function copilotIcon(name) {
    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var attributes = Ember.merge({ class: name }, hash);
    if (attributes.class.indexOf(name) === -1) {
      attributes.class += ' ' + name;
    }

    return (0, _svgJar.svgJar)(name, attributes);
  }

  /**
    `{{copilot-icon}}` is used to displays an svg icon
  
    ```htmlbars
    {{copilot-icon 'add'}}
    ```
  
    @module copilot/helpers/copilot-icon
    @augments Ember.Helper
    @param {String} name The name of the icon in `node_modules/@condenast/formation-styleguide/assets/icons` to embed
    @param {Object} [attributes] Optional attributes to add to the svg.
   */
  exports.default = Ember.Helper.helper(function (_ref, hash) {
    var _ref2 = _slicedToArray(_ref, 1),
        name = _ref2[0];

    return copilotIcon(name, hash);
  });
});
define('auth-app/routes/forgotpassword', ['exports', 'auth-app/models/forgotpassword', 'auth-app/mixins/unauthenticated-route'], function (exports, _forgotpassword, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ForgotPasswordRoute = Ember.Route.extend(_unauthenticatedRoute.default, {

    model: function model() {
      return _forgotpassword.default.create();
    }

  });

  exports.default = ForgotPasswordRoute;
});
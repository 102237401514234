define('auth-app/controllers/resetpassword', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var controller = Ember.inject.controller;
  exports.default = Ember.Controller.extend({

    login: controller(),

    actions: {

      submit: function submit() {
        var model = this.get('model');
        model.get('validationErrors').clear();
        if (model.validate()) {
          var token = model.get('resetToken');
          var data = model.getProperties('newPassword');

          Ember.$.ajax({
            url: '/auth/resetpassword/' + token,
            type: 'PUT',
            data: data
          }).then(function () {
            this.set('login.resetSuccess', true);
            this.transitionToRoute('login');
          }.bind(this), function (response) {
            this.get('model.validationErrors.fullMessages').pushObject(response.responseText);
          }.bind(this));
        }
      }
    }
  });
});
define('auth-app/components/copy-count', ['exports', 'formation-ember-components/components/copy-count/component', 'npm:@condenast/copilot-markdown-shim', 'npm:@condenast/copilot-markdown-shim-sanitize'], function (exports, _component, _copilotMarkdownShim, _copilotMarkdownShimSanitize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _component.default;
    }
  });
});
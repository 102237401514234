define('auth-app/routes/index', ['exports', 'auth-app/mixins/unauthenticated-route'], function (exports, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var IndexRoute = Ember.Route.extend(_unauthenticatedRoute.default, {
    redirect: function redirect() {
      this.transitionTo('login');
    }
  });

  exports.default = IndexRoute;
});
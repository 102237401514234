define('auth-app/services/login', ['exports', 'ic-ajax'], function (exports, _icAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    login: function login(data, controller) {
      return (0, _icAjax.default)({
        url: '/auth/login',
        type: 'POST',
        dataType: 'json',
        data: data
      }).then(function (response) {

        // If two factor authentication is not enabled, redirect back to the
        // service using copilot-auth for authentication
        if (response.redirectURI && response.code) {
          var redirect = response.redirectURI + '?code=' + response.code;
          window.location = redirect;

          // If two factor authentication is enabled, transition to the
          // appropriate post-login route (second factor setup or login)

          // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
        } else if (response.redirect && response.user_id) {
          controller.transitionToRoute(response.redirect, response.user_id);

          // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
        } else if (response.redirect && response.token) {
          controller.transitionToRoute(response.redirect, response.token);
        } else if (response.redirect) {
          // Or if logging into copilot-auth directly, create the session and redirect to root
          controller.transitionToRoute(response.redirect);
        } else {
          this.get('session').authenticate('authenticator:copilot');
          controller.transitionToRoute('/');
        }
      }.bind(this));
    },

    loginOtp: function loginOtp(data, controller) {
      return (0, _icAjax.default)({
        url: '/auth/login-otp',
        type: 'POST',
        dataType: 'json',
        data: data
      }).then(this.setRedirect.bind(controller)).then(this.authenticateSession.bind(controller)).then(this.doRedirect.bind(controller));
    },

    createResetOtpToken: function createResetOtpToken() {
      return (0, _icAjax.default)({
        url: '/auth/reset-otp',
        type: 'POST'
      });
    },
    resetOtp: function resetOtp(token) {
      return (0, _icAjax.default)({
        url: '/auth/reset-otp/' + token,
        type: 'DELETE'
      });
    },


    setRedirect: function setRedirect(response) {
      return new Ember.RSVP.Promise(function (resolve) {
        if (response.redirectURI && response.code) {
          var redirect = response.redirectURI + '?code=' + response.code;
          this.set('redirect', redirect);
        }
        resolve();
      }.bind(this));
    },

    authenticateSession: function authenticateSession() {
      return this.get('session').authenticate('authenticator:copilot');
    },

    doRedirect: function doRedirect() {
      var redirect = this.get('redirect');
      if (redirect) {
        window.location = redirect;
      } else {
        this.transitionToRoute('login');
      }
    }

  });
});
define('auth-app/routes/login', ['exports', 'auth-app/models/login', 'auth-app/mixins/unauthenticated-route'], function (exports, _login, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LoginRoute = Ember.Route.extend(_unauthenticatedRoute.default, {
    queryParams: {
      token: {
        refreshModel: true
      },
      allowOkta: {
        refreshModel: true
      }
    },
    model: function model() {
      return _login.default.create();
    }

  });

  exports.default = LoginRoute;
});
define('auth-app/components/mark-overlay', ['exports', 'ember-overlays/components/mark-overlay'], function (exports, _markOverlay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _markOverlay.default;
    }
  });
});
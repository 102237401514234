define('auth-app/models/usersetup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UserSetupModel = Ember.Object.extend(Ember.Validations, {

    firstName: null,
    lastName: null,
    email: null,
    role: null,
    userName: null,
    newPassword: null,
    repeatPassword: null,
    setupToken: null,

    validations: {
      newPassword: {
        length: { minimum: 8 },
        custom: {
          validator: function validator(obj, attr, value) {
            var errors = obj.get('validationErrors');
            if (!(value && value.match(/^(?=.*\d)(?=.*[A-Z])/))) {
              errors.add(attr, 'invalidPassword');
            }
          }
        }
      },
      repeatPassword: {
        presence: true,
        match: {
          property: 'newPassword'
        }
      }
    }

  });

  exports.default = UserSetupModel;
});
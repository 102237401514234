define('auth-app/routes/logout', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ic-ajax'], function (exports, _authenticatedRouteMixin, _icAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LogoutRoute = Ember.Route.extend(_authenticatedRouteMixin.default, {

    beforeModel: function beforeModel() {
      if (this.session.isAuthenticated) {
        (0, _icAjax.default)({
          url: '/auth/logout',
          type: 'POST'
        }).then(function () {
          Ember.$.removeCookie('brand', {
            path: '/'
          });
          this.transitionTo('/login');
        }.bind(this));
      }
    }

  });

  exports.default = LogoutRoute;
});
define('auth-app/router', ['exports', 'auth-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {

    this.route('login');

    this.route('usersetup', {
      path: 'user-setup/:token'
    });

    this.route('setup-otp', {
      path: 'setup-otp/:id'
    });

    this.route('reset-otp', {
      path: 'reset-otp/:token'
    });

    this.route('logout');

    this.route('forgotpassword');

    this.route('resetpassword', {
      path: 'resetpassword/:token'
    });

    this.route('login-otp');

    this.route('help-otp');

    this.route('article-mock');

    this.route('no-auth');

    this.route('no-okta');

    this.route('notfound', { path: '*path' });
  });

  exports.default = Router;
});
define('auth-app/models/login-otp', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LoginOtpModel = Ember.Object.extend(Ember.Validations, {

    code: null,
    failedAttempts: 0,

    validations: {
      code: {
        presence: true
      }
    },
    noLabelValidations: {
      code: true
    }
  });

  exports.default = LoginOtpModel;
});
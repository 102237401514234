define("auth-app/locales/en/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "condeNast": "Condé Nast",
    "fieldNames": {
      "userName": "Username or Email",
      "email": "Email",
      "password": "Password",
      "newPassword": "New Password",
      "repeatPassword": "Password Again",
      "rememberMe": "Remember Me",
      "verificationCode": "Verification Code"
    },
    "placeholder": {
      "text": "Enter {{labelText}} here",
      "password": "Enter {{labelText}}",
      "userName": "Username or email address"
    },
    "validationMessage": {
      "invalidPassword": "must contain at least 1 number and 1 uppercase letter.",
      "invalidCode": "The code you entered is invalid.",
      "resetCodeSuggest": "If you no longer have your 16-character secret key, you will need to contact an admin from your team or email formation-support@condenast.com for a reset."
    },
    "components": {
      "validation-summary": {
        "issues": "Please fix the following issues before submitting the form:"
      },
      "help-otp": {
        "sixDigitCode": "Help with Six Digit-Code?",
        "sixDigitCodeHelp": "The six-digit code on your smartphone app or browser extension refreshes every 30 seconds. If you've tried multiple codes and none are working, or if you're using a new device, then you'll need to reset it.",
        "moreInfo": "For more information, visit the <a href=\"//learn.copilot.conde.io/help/for-editors/editors-faq\">Copilot Learn Site<\/a>",
        "reset2FA": "Reset My Six-Digit Code"
      }
    }
  };
});
define('auth-app/instance-initializers/logger-service', ['exports', 'copilot-logger-service/services/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {

    var userService = application.lookup('service:user');
    if (userService) {
      var loggerService = application.lookup('service:logger');
      userService.logger = loggerService;
    }
  }

  exports.default = {
    name: 'logger-service',
    initialize: initialize
  };
});
define('auth-app/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.fromRoute('login'), this.toRoute('login-otp'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('login'), this.toRoute('forgotpassword'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('usersetup'), this.toRoute('setup-otp'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('resetpassword'), this.toRoute('login'), this.use('toLeft'), this.reverse('toRight'));
  };
});
define('auth-app/lib/log-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var error = this.error;
    var stack = this.error && this.error.stack;
    Ember.$.ajax('/log', {
      type: 'POST',
      data: {
        level: 'error',
        msg: 'Ember onerror caught',
        error: error,
        caught: stack
      }
    });
  };
});
define('auth-app/services/ember-overlays', ['exports', 'ember-overlays/services/ember-overlays'], function (exports, _emberOverlays) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberOverlays.default;
    }
  });
});
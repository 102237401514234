define('auth-app/routes/resetpassword', ['exports', 'auth-app/models/usersetup', 'ic-ajax', 'auth-app/mixins/unauthenticated-route'], function (exports, _usersetup, _icAjax, _unauthenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ResetPasswordRoute = Ember.Route.extend(_unauthenticatedRoute.default, {

    model: function model(params) {
      var token = params.token;

      return (0, _icAjax.default)('/auth/resetpassword/' + token).then(function () {
        return _usersetup.default.create({
          resetToken: token
        });
      }, function () {
        return Ember.RSVP.reject('Token ' + token + ' not found');
      });
    },

    actions: {
      error: function error(e) {
        var error = {
          message: e,
          path: 'notfound'
        };
        return this.transitionTo('notfound', error);
      }
    }

  });

  exports.default = ResetPasswordRoute;
});